import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  message,
  Collapse,
  Spin,
  Row,
  Col,
  Typography
} from 'antd'
import { ApprovalStatus } from '../../../components/Admin/Approval/Approval'
import PartnerCommonData from '../../../components/Admin/Partner/Sections/PartnerCommonData'
import PartnerPersonalData from '../../../components/Admin/Partner/Sections/PartnerPersonalData'
import PartnerBank from '../../../components/Admin/Partner/Sections/PartnerBank'
import PartnerDocuments from '../../../components/Admin/Partner/Sections/PartnerDocuments'
import {
  ACCEPTED,
  DECLINED,
  FILLED,
  FILLED_IN_SHOPPER,
  FILLED_IN_TINKOFF,
  NONE,
  NOT_FILLED,
  oneMinute
} from '../../../Constants'
import {
  useQueryAdminPartnerById,
  useMutateAdminUpdatePartnerAction,
  useMutateAdminUpdatePartnerDocs,
  useMutateAdminUpdatePartnerSecurityStatus,
  useMutateAdminUpdatePartnerKind,
  useQueryAdminPartnerProfileFormById, useQueryAdminPartnerByPhone
} from '../../../api/react-query/adminPartners'
import ToArchive from '../../../components/Admin/Partner/ActionButtonModals/ToArchive'
import ToBan from '../../../components/Admin/Partner/ActionButtonModals/ToBan'
import Assets from '../../../components/Admin/Partner/Assets/Assets'
import UnBan from '../../../components/Admin/Partner/ActionButtonModals/UnBan'
import StatusHistory from '../../../components/Admin/Partner/StatusHistory/StatusHistory'
import DownloadContract from '../../../components/Admin/Partner/ContractView/DownloadContract'
import ProfileHistory from '../../../components/Admin/Partner/ProfileHistory/ProfileHistory'
import { profileScreen } from '../../../translates'
import { AppConfig, individual, partnerStatusCodes } from '../../../AppConfig'
import { createFormDataFromApi } from '../../../helper'
import { ConfigContext } from '../../../context/configContext'
import DocumentDoubleView from '../../../components/Admin/Partner/Sections/DocumentDoubleView'
import { ADMIN_PARTNERS_PAGE } from '../../../routes'
import PartnerInn from '../../../components/Admin/Partner/Sections/PartnerInn'

const { Panel } = Collapse
const { Title, Text } = Typography

function Partner ({ partnerId, partnerPhone }) {
  const {
    loadingData: { isLoadingAdminUpdateShopperStatus },
    modalData: { isEditProfileInfoRequest, refetchProfileInfo },
    isExternalCoordinator
  } = useContext(ConfigContext)
  const history = useHistory()
  const [partner, setPartner] = useState({})
  const [activeKey, setActiveKey] = useState(['common'])
  const [logHeader, setLogHeader] = useState('Partner list')

  const setPartnerData = (partnerData) => {
    const data = createFormDataFromApi(partnerData.profile, false)
    setPartner({
      ...partnerData,
      profile: {
        ...partner?.profile,
        ...data
      }
    })
  }

  const {
    data: adminPartnerByIdData,
    isError: isErrorAdminPartnerById,
    isLoading: isLoadingAdminPartnerById,
    isSuccess: isSuccessAdminPartnerById
  } = useQueryAdminPartnerById({
    partnerId,
    logHeader
  }, {
    enabled: !!partnerId,
    refetchInterval: oneMinute
  })
  useEffect(() => {
    if (isSuccessAdminPartnerById) {
      setLogHeader(false)
      setPartnerData({ ...adminPartnerByIdData?.partner })
    }
  }, [isSuccessAdminPartnerById, adminPartnerByIdData])

  useEffect(() => {
    if (isErrorAdminPartnerById) {
      message.error('Ошибка доступа к данным')
    }
  }, [isErrorAdminPartnerById])

  const {
    data: adminPartnerByPhoneData,
    isError: isErrorAdminPartnerByPhone,
    isLoading: isLoadingAdminPartnerByPhone,
    isSuccess: isSuccessAdminPartnerByPhone
  } = useQueryAdminPartnerByPhone({
    phone: partnerPhone
  }, {
    enabled: !!partnerPhone,
    refetchInterval: oneMinute
  })
  useEffect(() => {
    if (isSuccessAdminPartnerByPhone) {
      setLogHeader(false)
      setPartnerData({ ...adminPartnerByPhoneData?.partner })
    }
  }, [isSuccessAdminPartnerByPhone, adminPartnerByIdData])

  useEffect(() => {
    if (isErrorAdminPartnerByPhone) {
      message.error('Ошибка доступа к данным')
    }
  }, [isErrorAdminPartnerByPhone])

  const {
    data: adminPartnerFormByIdData,
    isError: isErrorAdminPartnerFormById,
    isLoading: isLoadingAdminPartnerFormById,
    isSuccess: isSuccessAdminPartnerFormById
  } = useQueryAdminPartnerProfileFormById(
    {
      partnerId, refetchProfileInfo
    },
    {
      enabled: Boolean(isEditProfileInfoRequest),
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0
    }
  )

  useEffect(() => {
    if (isSuccessAdminPartnerFormById && adminPartnerFormByIdData?.data && Object.keys(partner || {}).length) {
      setPartnerData({
        ...partner,
        profile: {
          ...partner?.profile,
          form: adminPartnerFormByIdData?.data
        }
      })
    } else if (isErrorAdminPartnerFormById) {
      message.error('Ошибка доступа к данным анкеты')
    }
  }, [isSuccessAdminPartnerFormById, isErrorAdminPartnerFormById, adminPartnerFormByIdData])

  const documents = useMemo(() => {
    const vacName = profileScreen.input.vaccinacionPhoto.name
    /**
     * Фото о вакцинации должно выводиться всегда, независимо от visible, (чтоб дать возможность загрузить если его нет)
     * но если нет видимых для пользователя документов, одну вакцинацию не выводим, вернём пустой массив
     */
    const filtered = (partner?.documents ?? []).filter(
      d => (!d.includes('bank') && partner?.profile[d]?.visible) || (d === vacName)
    )
      // убираем Инн-фото из списка документов. Фото показвыается в отдельной секции innDetails
      .filter(d => d !== profileScreen.input.innPhoto.name)
    return filtered.length === 1 && filtered[0] === vacName ? [] : filtered
  }, [partner])
  const docsStatus = useMemo(() => {
    const outcomes = documents
      // Если фото о вакцинации не загружено, считаем его принятым
      .map(d => d === profileScreen.input.vaccinacionPhoto.name && !partner?.profile?.[d]?.fileId ? ACCEPTED : partner?.profile?.[d]?.status ?? NONE)
    if (outcomes.includes(NONE)) return NONE
    if (outcomes.includes(DECLINED)) return DECLINED
    if (outcomes.includes(FILLED)) return FILLED
    return ACCEPTED
  }, [partner, documents])

  const {
    isLoading: isLoadingAdminUpdatePartnerDocs,
    mutateAsync: mutateAdminUpdatePartnerDocs
  } = useMutateAdminUpdatePartnerDocs()

  const {
    isLoading: isLoadingAdminUpdatePartnerSecurityStatus,
    isSuccess: isSuccessAdminUpdatePartnerSecurityStatus,
    isError: isErrorAdminUpdatePartnerSecurityStatus,
    data: dataAdminUpdatePartnerSecurityStatus,
    mutate: mutateAdminUpdatePartnerSecurityStatus
  } = useMutateAdminUpdatePartnerSecurityStatus()

  useEffect(() => {
    if (isSuccessAdminUpdatePartnerSecurityStatus) {
      if (dataAdminUpdatePartnerSecurityStatus?.errorMessage) {
        return message.error(dataAdminUpdatePartnerSecurityStatus?.errorMessage)
      }
      const msg = `Статус СБ партнера успешно изменен на "${dataAdminUpdatePartnerSecurityStatus?.partner?.profile?.securityCheck?.status}"`
      message.success(msg)
    }
    if (isErrorAdminUpdatePartnerSecurityStatus) {
      message.error(dataAdminUpdatePartnerSecurityStatus?.errorMessage || 'Не удалось изменить статус СБ партнеру')
    }
  }, [isSuccessAdminUpdatePartnerSecurityStatus, isErrorAdminUpdatePartnerSecurityStatus, dataAdminUpdatePartnerSecurityStatus])

  const handleCommit = useCallback(async (section, outcome, comment, cb) => {
    if (outcome) {
      try {
        const result = await mutateAdminUpdatePartnerDocs({
          id: partnerId || partner?.id,
          outcome,
          body: {
            documentType: section,
            comment
          }
        })
        if (result?.isSuccess) {
          setPartnerData({ ...result?.partner })
          cb?.()
          if (isExternalCoordinator && result?.partner?.profile?.statusCode !== partnerStatusCodes.verification) {
            message.success('Партнер проверен')
            history.replace(ADMIN_PARTNERS_PAGE)
          }
        } else {
          message.error(result?.errorMessage || 'Ошибка доступа к данным')
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [history, isExternalCoordinator, mutateAdminUpdatePartnerDocs, partnerId, partner?.id, setPartnerData])

  const {
    isLoading: isLoadingAdminUpdatePartnerAction,
    mutateAsync: mutateAdminUpdatePartnerAction
  } = useMutateAdminUpdatePartnerAction()

  const handlePartnerActions = async (type, action) => {
    if (action && type) {
      try {
        const result = await mutateAdminUpdatePartnerAction({ id: partnerId || partner?.id, type, action })
        if (result?.isSuccess) {
          setPartnerData({ ...result?.partner })
        } else {
          message.error(result?.errorMessage || 'Ошибка доступа к данным')
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const {
    isLoading: isLoadingAdminUpdatePartnerKind,
    isSuccess: isSuccessAdminUpdatePartnerKind,
    data: dataAdminUpdatePartnerKind,
    mutateAsync: mutateAdminUpdatePartnerKind
  } = useMutateAdminUpdatePartnerKind()

  useEffect(() => {
    if (isSuccessAdminUpdatePartnerKind) {
      if (dataAdminUpdatePartnerKind?.isSuccess) {
        setPartnerData({ ...dataAdminUpdatePartnerKind?.partner })
      } else {
        message.error(dataAdminUpdatePartnerKind?.errorMessage || 'Ошибка доступа к данным')
      }
    }
  },
  [isSuccessAdminUpdatePartnerKind, dataAdminUpdatePartnerKind])

  const { profile } = partner

  const handleChangeCollapse = keys => {
    if (!keys.includes('common')) return null
    setActiveKey(keys)
  }

  const bankDetailStatus = useMemo(() => {
    if (profile?.bankDetails?.bankDetailsSource === AppConfig.tinkoffId.bankDetailsSource) {
      return FILLED_IN_TINKOFF
    }
    if (profile?.externalBankDetails) {
      const bankAccount = profile?.bankDetails?.bankAccount?.trim()
      const bankBik = profile?.bankDetails?.bankBik?.trim()
      return bankBik?.length && bankAccount?.length
        ? FILLED_IN_SHOPPER
        : NOT_FILLED
    }
    return profile?.bankDetails?.status
  }, [profile])

  const bankDetailsForEdit = useMemo(() => ({
    ...profile?.bankDetails,
    allowReview: profile?.externalBankDetails ? false : profile?.bankDetails?.allowReview
  }), [profile])

  return (
    <>
      <Row justify='space-between' align='middle'>
        <Col>
          <Title type='success'>Профиль партнера</Title>
        </Col>
        <Col>
          <ToArchive allowArchive={profile?.allowArchive} />
          <ToBan allowBan={profile?.allowBan} />
          <UnBan allowUnban={profile?.allowUnban} />
        </Col>
      </Row>
      <Spin
        spinning={
          isLoadingAdminUpdatePartnerAction ||
          isLoadingAdminUpdateShopperStatus ||
          isLoadingAdminPartnerById ||
          isLoadingAdminPartnerByPhone ||
          isLoadingAdminPartnerFormById ||
          isLoadingAdminUpdatePartnerDocs ||
        isLoadingAdminUpdatePartnerSecurityStatus
        }
        size='large'
      >
        <Collapse activeKey={activeKey} onChange={handleChangeCollapse}>
          <Panel
            key='common' showArrow={false}
            header={<Text className='vertical-align-sub'>Основные данные</Text>}
            extra={
              <div className='d-flex'>
                {profile?.allowContractView && <div className='mr-3'><DownloadContract partnerId={partnerId || partner?.id} /></div>}
                {profile?.allowProfileHistoryView && <div className='mr-3'><ProfileHistory partnerId={partnerId || partner?.id} /></div>}
                {profile?.allowHistoryView && <div className='mr-3'><StatusHistory partnerId={partnerId || partner?.id} /></div>}
                {
                  (profile?.allowTransfer || profile?.allowReturn || profile?.allowWriteOff || profile?.assets?.allowList) &&
                    <Assets partner={partner} />
                }
              </div>
            }
          >
            <PartnerCommonData
              profile={profile}
              loading={
                isLoadingAdminUpdateShopperStatus || isLoadingAdminUpdatePartnerAction ||
                isLoadingAdminUpdatePartnerSecurityStatus || isLoadingAdminUpdatePartnerKind
              }
              onPartnerActions={handlePartnerActions}
              onSubmitPartnerKind={mutateAdminUpdatePartnerKind}
              onChangeSecurityStatus={mutateAdminUpdatePartnerSecurityStatus}
              partnerId={partnerId || partner?.id}
              verifierUserName={partner?.verifierUserName}
              setPartner={setPartnerData}
            />
          </Panel>
          {profile?.personalData && profile.personalData.visible &&
            <Panel
              header='Анкетные данные' key='personalData'
              extra={<ApprovalStatus status={profile?.personalData?.status} />}
            >
              <PartnerPersonalData
                partnerId={partner?.id}
                partnerUuid={partner?.uuid}
                profile={profile}
                loading={isLoadingAdminUpdatePartnerDocs}
                loadingPartnerData={isLoadingAdminPartnerById || isLoadingAdminPartnerByPhone}
                onCommit={handleCommit}
                onCommitDocument={handleCommit}
              />
            </Panel>}
          {profile?.innDetails?.visible &&
            <Panel
              header='ИНН' key='innDocument'
              extra={<ApprovalStatus status={profile?.innDetails?.status} forceStatus={profile?.innDetails?.statusText} />}
            >
              <PartnerInn
                profile={profile}
                partnerId={partner?.id}
                loading={isLoadingAdminUpdatePartnerDocs}
                onCommit={handleCommit}
              />
            </Panel>}
          {profile?.bankDetails?.visible &&
            <Panel
              header='Банковские реквизиты' key='bankDocument'
              extra={<ApprovalStatus status={bankDetailStatus} />}
            >
              <PartnerBank
                partnerId={partner?.id}
                bankDetails={bankDetailsForEdit}
                bankDetailsPhoto={profile?.bankDetailsPhoto}
                loading={isLoadingAdminUpdatePartnerDocs}
                onCommit={handleCommit}
                allowEdit={profile?.externalBankDetails ? false : profile?.bankDetails?.allowEdit}
                isIndividual={profile?.personalData?.legalForm === individual}
              />
            </Panel>}
          {documents?.length &&
            <Panel
              header={
                <>
                  Документы
                  <DocumentDoubleView
                    profile={profile}
                    loading={isLoadingAdminUpdatePartnerDocs}
                    onCommit={handleCommit}
                  />
                </>
              }
              key='documents'
              extra={<ApprovalStatus status={docsStatus} />}
            >
              <PartnerDocuments
                partnerId={partner?.id}
                profile={profile}
                documents={documents}
                loading={isLoadingAdminUpdatePartnerDocs}
                onCommit={handleCommit}
                setPartner={setPartnerData}
                getDocumentStatus={(d) => profile?.[d]?.status}
              />
            </Panel>}
        </Collapse>
      </Spin>
    </>
  )
}

export default withRouter(observer(Partner))
