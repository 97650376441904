import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Layout, Button, Form, Typography, PageHeader, Checkbox, Spin, message } from 'antd'
import { observer } from 'mobx-react'

import './OfferStyle.css'
import { digestMessage, getMedicalOfferParams, renderMedicalOfferTemplate } from '../../../helper'
import { errors, offerScreen } from '../../../translates'
import { formLayout, partnerRoles4MultiOffer, policyTypes } from '../../../Constants'
import {
  useGetAssemblyDeliveryPolicy,
  useGetCyberSecurityPolicyQuery, useGetMedicalAcceptOffer,
  useGetOffer,
  useMutateAcceptOffer,
  useMutateAssemblyDeliveryPolicyAccept,
  useMutateCyberSecurityPolicyAccept, useMutateMedicalOfferAccept
} from '../../../api/react-query/partners'
import { AppConfig, partnerStatusCodes } from '../../../AppConfig'
import { useStores } from '../../../Store/Store'
import { Link } from 'react-router-dom'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import MedicalAcceptOffer from '../../Common/FormItems/Medical/MedicalAcceptOffer'
import DocWithNotices from '../PolicyAgreement/DocWithNotices'

const { Content } = Layout
const { Paragraph } = Typography

const offerSteps = {
  roleOffer: 'roleOffer',
  cyberSecurityPolicy: 'cyberSecurityPolicy',
  assemblyAndDeliveryPolicy: 'assemblyAndDeliveryPolicy',
  lmkAgreement: 'lmkAgreement'
}

const Offer = ({ onSuccess }) => {
  const { partnerProfile } = useStores()
  const profile = partnerProfile.profile

  const onlyRoleOffer = useMemo(() => {
    return !partnerRoles4MultiOffer.includes(profile?.personalData?.roleCode)
  }, [profile?.personalData?.roleCode])

  const currentOffer = useMemo(() => {
    if ([partnerStatusCodes.loginIssued].includes(profile?.statusCode) && profile?.offerExpires) {
      return offerSteps.roleOffer
    }
    if ([partnerStatusCodes.profileFilled].includes(profile?.statusCode) && !profile?.offerActive) {
      return offerSteps.roleOffer
    }
    if ([partnerStatusCodes.profileFilled, partnerStatusCodes.loginIssued].includes(profile?.statusCode) &&
      !profile?.cyberSecurityPolicy?.isValid &&
      partnerRoles4MultiOffer.includes(profile?.personalData?.roleCode)
    ) {
      return offerSteps.cyberSecurityPolicy
    }
    if ([partnerStatusCodes.profileFilled, partnerStatusCodes.loginIssued].includes(profile?.statusCode) &&
      !profile?.assemblyAndDeliveryPolicy?.isValid &&
      partnerRoles4MultiOffer.includes(profile?.personalData?.roleCode)
    ) {
      return offerSteps.assemblyAndDeliveryPolicy
    }
    if ([partnerStatusCodes.profileFilled,
      partnerStatusCodes.loginIssued,
      partnerStatusCodes.verification,
      partnerStatusCodes.verified
    ].includes(profile?.statusCode) &&
      profile?.lmkDetails?.hasMedicalBook &&
      !profile?.lmkDetails?.agreementAccepted
    ) {
      return offerSteps.lmkAgreement
    }
  }, [profile])

  const areAllAccepted = (profile) => {
    if (
      (
        (
          !partnerRoles4MultiOffer.includes(profile?.personalData?.roleCode) &&
          !profile?.offerExpires &&
          profile?.offerActive
        ) || (
          partnerRoles4MultiOffer.includes(profile?.personalData?.roleCode) &&
          !profile?.offerExpires &&
          profile?.offerActive &&
          profile?.cyberSecurityPolicy?.isValid &&
          profile?.assemblyAndDeliveryPolicy?.isValid
        )
      ) && (
        (profile?.lmkDetails?.hasMedicalBook && profile?.lmkDetails?.agreementAccepted) ||
        !profile?.lmkDetails?.hasMedicalBook
      )
    ) {
      return true
    }
    return false
  }

  const {
    data: dataRoleOffer,
    isError: isErrorRoleOffer,
    isLoading: isLoadingRoleOffer
  } = useGetOffer()

  const {
    data: dataCyberSecurityPolicy,
    isError: isErrorCyberSecurityPolicy
  } = useGetCyberSecurityPolicyQuery({
    enabled: currentOffer === offerSteps.cyberSecurityPolicy
  })

  const {
    data: dataAssemblyDeliveryPolicy,
    isError: isErrorAssemblyDeliveryPolicy
  } = useGetAssemblyDeliveryPolicy({
    enabled: currentOffer === offerSteps.assemblyAndDeliveryPolicy
  })

  const {
    data: dataMedicalOffer,
    isError: isErrorMedicalOffer
  } = useGetMedicalAcceptOffer({
    enabled: currentOffer === offerSteps.lmkAgreement
  })

  useEffect(() => {
    if (isErrorRoleOffer || isErrorCyberSecurityPolicy || isErrorAssemblyDeliveryPolicy || isErrorMedicalOffer) {
      message.error(offerScreen.errors.offer)
    }
  }, [isErrorRoleOffer, isErrorCyberSecurityPolicy, isErrorAssemblyDeliveryPolicy, isErrorMedicalOffer])

  const offerData = useMemo(() => {
    return dataRoleOffer || {}
  }, [dataRoleOffer])

  const {
    mutate: acceptRoleOffer,
    data: dataAcceptRoleOffer,
    isLoading: isLoadingAcceptRoleOffer,
    isError: isErrorAcceptRoleOffer
  } = useMutateAcceptOffer()

  const {
    mutate: acceptCyberSecurityPolicy,
    data: dataAcceptCyberSecurityPolicy,
    isLoading: isLoadingAcceptCyberSecurityPolicy,
    isError: isErrorAcceptCyberSecurityPolicy
  } = useMutateCyberSecurityPolicyAccept()

  const {
    mutate: acceptAssemblyDeliveryPolicy,
    data: dataAcceptAssemblyDeliveryPolicy,
    isLoading: isLoadingAcceptAssemblyDeliveryPolicy,
    isError: isErrorAcceptAssemblyDeliveryPolicy
  } = useMutateAssemblyDeliveryPolicyAccept()

  const {
    mutate: acceptMedicalOffer,
    data: dataAcceptMedicalOffer,
    isLoading: isLoadingAcceptMedicalOffer,
    isError: isErrorAcceptMedicalOffer
  } = useMutateMedicalOfferAccept()

  useEffect(() => {
    if (dataAcceptRoleOffer?.isSuccess && dataAcceptRoleOffer?.partner) {
      partnerProfile?.setPartnerProfile(dataAcceptRoleOffer?.partner)
      if (areAllAccepted(dataAcceptRoleOffer?.partner?.profile)) {
        onSuccess(AppConfig.partnerSteps.stepSuccess)
      } else {
        form.submit()
      }
    } else if (isErrorAcceptRoleOffer || dataAcceptRoleOffer?.errorMessage) {
      message.error(dataAcceptRoleOffer?.errorMessage || 'Ошибка подтверждения оферты')
    }
  }, [dataAcceptRoleOffer, isErrorAcceptRoleOffer])

  useEffect(() => {
    if (dataAcceptCyberSecurityPolicy?.isSuccess && dataAcceptCyberSecurityPolicy?.profile) {
      partnerProfile?.setPartnerProfile({ profile: dataAcceptCyberSecurityPolicy?.profile })
      if (areAllAccepted(dataAcceptCyberSecurityPolicy?.profile)) {
        onSuccess(AppConfig.partnerSteps.stepSuccess)
      } else {
        form.submit()
      }
    } else if (isErrorAcceptCyberSecurityPolicy || dataAcceptCyberSecurityPolicy?.errorMessage) {
      message.error(dataAcceptCyberSecurityPolicy?.errorMessage || 'Ошибка подтверждения оферты')
    }
  }, [dataAcceptCyberSecurityPolicy, isErrorAcceptCyberSecurityPolicy])

  useEffect(() => {
    if (dataAcceptAssemblyDeliveryPolicy?.isSuccess && dataAcceptAssemblyDeliveryPolicy?.profile) {
      partnerProfile?.setPartnerProfile({ profile: dataAcceptAssemblyDeliveryPolicy?.profile })
      if (areAllAccepted(dataAcceptAssemblyDeliveryPolicy?.profile)) {
        onSuccess(AppConfig.partnerSteps.stepSuccess)
      } else {
        form.submit()
      }
    } else if (isErrorAcceptAssemblyDeliveryPolicy || dataAcceptAssemblyDeliveryPolicy?.errorMessage) {
      message.error(dataAcceptAssemblyDeliveryPolicy?.errorMessage || 'Ошибка подтверждения оферты')
    }
  }, [dataAcceptAssemblyDeliveryPolicy, isErrorAcceptAssemblyDeliveryPolicy])

  useEffect(() => {
    if (dataAcceptMedicalOffer?.isSuccess && dataAcceptMedicalOffer?.profile) {
      partnerProfile?.setPartnerProfile({ profile: dataAcceptMedicalOffer?.profile })
      if (areAllAccepted(dataAcceptMedicalOffer?.profile)) {
        onSuccess(AppConfig.partnerSteps.stepSuccess)
      }
    } else if (isErrorAcceptMedicalOffer || dataAcceptMedicalOffer?.errorMessage) {
      message.error(dataAcceptMedicalOffer?.errorMessage || errors.acceptLmkAgreement)
    }
  }, [dataAcceptMedicalOffer, isErrorAcceptMedicalOffer])

  const acceptRoleOfferAsync = async () => {
    const offerHash = await digestMessage(offerData.offer?.subject + offerData.offer?.body + offerData.offer?.agreementTerm)
    acceptRoleOffer({
      offerId: offerData.offer?.id,
      offerHash,
      validityFrom: offerData.validityFrom,
      validityTo: offerData.validityTo
    })
  }

  const acceptMedicalOfferAsync = async function (offerData, profile) {
    const content = renderMedicalOfferTemplate(offerData?.template?.content, profile)
    const hash = await digestMessage(content)
    acceptMedicalOffer({
      templateId: offerData?.template?.id,
      signHash: hash,
      signData: getMedicalOfferParams(profile)
    })
  }

  const onFinish = values => {
    if (currentOffer !== offerSteps.lmkAgreement && !values.accept) return
    if (profile?.lmkDetails?.hasMedicalBook && !profile?.lmkDetails?.agreementAccepted && !values.medicalAcceptOffer) return

    if (currentOffer === offerSteps.roleOffer) {
      acceptRoleOfferAsync()
    }
    if (currentOffer === offerSteps.cyberSecurityPolicy) {
      acceptCyberSecurityPolicy({ agreementId: dataCyberSecurityPolicy?.agreement?.id })
    }
    if (currentOffer === offerSteps.assemblyAndDeliveryPolicy) {
      acceptAssemblyDeliveryPolicy({ agreementId: dataAssemblyDeliveryPolicy?.agreement?.id })
    }
    if (currentOffer === offerSteps.lmkAgreement) {
      acceptMedicalOfferAsync(dataMedicalOffer, profile)
    }
  }

  const myRef = useRef(null)
  useEffect(() => {
    if (myRef.current) {
      document.body.scrollIntoView({ behavior: 'smooth' })
    }
  }, [myRef])
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const [form] = Form.useForm()
  const [requiredMark, setRequiredMarkType] = useState('required')

  const onRequiredTypeChange = ({ requiredMark }) => {
    setRequiredMarkType(requiredMark)
  }

  return (
    <div ref={myRef}>
      <Spin spinning={isLoadingRoleOffer} size='large'>
        <Content className='Offer'>
          <Form
            {...formLayout}
            name='basic'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            layout='vertical'
            initialValues={{ requiredMark, remember: true }}
            onValuesChange={onRequiredTypeChange}
            requiredMark={requiredMark}
          >
            {
              currentOffer !== offerSteps.lmkAgreement && (
                <>
                  <Paragraph>{offerScreen.prefixTitle}</Paragraph>
                  <PageHeader title={offerData.offer?.subject} />
                </>
              )
            }

            {
              onlyRoleOffer && currentOffer !== offerSteps.lmkAgreement &&
                <Paragraph className='document'>
                  <DocWithNotices docData={offerData.offer} />
                </Paragraph>
            }

            {
              currentOffer !== offerSteps.lmkAgreement && (
                <Form.Item
                  name='accept'
                  valuePropName='checked'
                  rules={[{
                    required: true,
                    transform: value => (value || undefined),
                    type: 'boolean',
                    message: offerScreen.offerText
                  }]}
                >
                  <Checkbox>
                    {offerScreen.input.accept.placeholder}:{' '}
                    {offerScreen.offerParts.roleOffer}
                    {
                      !onlyRoleOffer && (
                        <>
                          ,{' '}
                          <Link
                            to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.cyberSecurityPolicy}`}
                          >
                            {offerScreen.offerParts.cyberSecurityPolicy}
                          </Link>,{' '}
                          <Link
                            to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.assemblyAndDeliveryPolicy}`}
                          >
                            {offerScreen.offerParts.assemblyAndDeliveryPolicy}
                          </Link>.
                        </>
                      )
                    }
                  </Checkbox>
                </Form.Item>
              )
            }

            {profile?.lmkDetails?.hasMedicalBook && !profile?.lmkDetails?.agreementAccepted && (
              <MedicalAcceptOffer onMedicalOfferShow={() => {}} />
            )}

            {
              !onlyRoleOffer && currentOffer !== offerSteps.lmkAgreement &&
                <Paragraph className='document'>
                  <DocWithNotices docData={offerData.offer} />
                </Paragraph>
            }

            <Button
              block
              type='primary'
              htmlType='submit'
              className='continue-button'
              loading={isLoadingAcceptRoleOffer ||
                  isLoadingAcceptCyberSecurityPolicy ||
                  isLoadingAcceptAssemblyDeliveryPolicy ||
              isLoadingAcceptMedicalOffer}
            >
              {offerScreen.button.submit}
            </Button>
          </Form>
        </Content>
      </Spin>
    </div>
  )
}
export default observer(Offer)
