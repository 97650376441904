import React from 'react'
import { successScreen } from '../../../translates'
import { Alert, Col, Row, Typography } from 'antd'
import { useStores } from '../../../Store/Store'

const { Title, Text } = Typography

function NotFoundStep () {
  const { partnerProfile } = useStores()

  return (
    <div className='error-step'>
      <Row justify='space-between' className='flex-column'>
        <Col>
          <Title level={3}>{successScreen.loginNotFound}</Title>
          <Text>
            {
              partnerProfile?.profile?.declineReason && (
                <Alert
                  description={partnerProfile?.profile?.declineReason}
                  type='error'
                />
              )
            }
          </Text>
        </Col>
        <Col span={24}>
          <img src='/img/alert-yellow.png' className='processing alert' />
        </Col>
        <Col span={24}>
        &nbsp;
        </Col>
      </Row>
    </div>
  )
}

export default NotFoundStep
