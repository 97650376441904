import React from 'react'
import { Col, Row, Typography } from 'antd'

const { Title } = Typography

export default function CheckingForm ({ subText = '', className = '' }) {
  // const handleReload = useCallback(() => {
  //   location.reload()
  // }, [])

  return (
    <Row className={`flex-space-between form ${className}`}>
      <Col>
        <Title level={3}>Ваша анкета на проверке</Title>
        {subText ||
          <>
            {/* <Text>Запрос отправлен на проверку.</Text> */}
            {/* <br /> */}
            {/* <Text>Обычно это занимает около 5 мин.</Text> */}
            {/* <br /> */}
            {/* <Text>Обновите страницу чуть позже или дождитесь СМС — пришлём его, как закончим.</Text> */}
          </>}
      </Col>
      <Col span={24}>
        <img src='/img/clock.png' className='processing' />
      </Col>
      <Col span={24}>
        &nbsp;
      </Col>
      {/* <Col span={24} className='btn-block'> */}
      {/*  <Button */}
      {/*    block */}
      {/*    size='large' */}
      {/*    type='primary' */}
      {/*    className='margin-bottom-20 ant-btn-success' */}
      {/*    onClick={handleReload} */}
      {/*  > */}
      {/*    Обновить */}
      {/*  </Button> */}
      {/* </Col> */}
    </Row>
  )
}
