import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
// import MobileHeader from '../../../components/Partner/MobileHeader/MobileHeader'
import { Alert, Button, Checkbox, Col, Form, Layout, message, Row, Spin, Typography } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { InfoCircleOutlined } from '@ant-design/icons'

import ErrorBlock from '../../../components/Partner/Profile/ErrorBlock'
import { partnerStatusCodes, employmentTypeStatusCodes, individual, selfEmployed, AppConfig } from '../../../AppConfig'
import { errors, offerScreen, profileScreen } from '../../../translates'
import {
  createApiDataFromForm,
  createFormDataFromApi,
  digestMessage,
  simpleReducer,
  handleMyTax,
  validateAllPhotosUploading
} from '../../../helper'
import '../ProfileScreen/ProfileEditInfoScreenStyle.css'
import '../../../components/Partner/Profile/ProfileStyle.css'
// import { LeftOutlined } from '@ant-design/icons'
import { useStores } from '../../../Store/Store'
import {
  useGetAssemblyDeliveryPolicy,
  useGetCyberSecurityPolicyQuery,
  useGetEmploymentTypeForm, useGetOffer,
  useMutatePostEmploymentTypeForm
} from '../../../api/react-query/partners'
import { formLayout, nbsp, partnerRoles4MultiOffer, policyTypes } from '../../../Constants'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import BankDetails from '../../../components/Partner/Profile/BankDetails'
import Documents from '../../../components/Partner/Profile/Documents'
import { EmailFormItem } from '../../../components/Common'
import { PROFILE_EMPLOYMENT_TYPE_STATE_INIT } from '../ProfileScreen/ProfileMainScreen'
import LegalFormItem from '../../../components/Common/FormItems/LegalFormItem/LegalFormItem'
import CheckingForm from '../../../components/Partner/CheckingForm'

const { Content } = Layout
const { Title, Text } = Typography

const initialOffersData = {
  roleOffer: null,
  cyberSecurityPolicy: null,
  assemblyDeliveryPolicy: null
}

function FormEmploymentTypeScreen ({ extState, setExtState }) {
  const {
    partnerProfile: {
      profile,
      profile: {
        employmentTypeFormStatus
      },
      setPartnerProfile
    }
  } = useStores()
  const history = useHistory()
  const [form] = Form.useForm()
  const [isTouched, setIsTouched] = useState(false)
  const [infoStep, setInfoStep] = useState(true)
  const [trigger, setTrigger] = useState(false)
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [offersData, setOffersData] = useReducer(simpleReducer, initialOffersData)

  const role = useMemo(() => profile?.personalData?.roleCode, [profile?.personalData?.roleCode])

  const { data: dataRoleOffer, isError: isErrorRoleOffer } = useGetOffer(role, {
    enabled: Boolean(role)
  })
  const { data: dataCyberSecurityPolicy, isError: isErrorCyberSecurityPolicy } = useGetCyberSecurityPolicyQuery()
  const { data: dataAssemblyDeliveryPolicy, isError: isErrorAssemblyDeliveryPolicy } = useGetAssemblyDeliveryPolicy()

  useEffect(() => {
    if (isErrorRoleOffer || isErrorCyberSecurityPolicy || isErrorAssemblyDeliveryPolicy) {
      message.error('Ошибка получения данных о соглашениях об оказании услуг')
    }
  }, [isErrorRoleOffer, isErrorCyberSecurityPolicy, isErrorAssemblyDeliveryPolicy])

  useEffect(() => {
    const getHash = async (dataRoleOffer) => {
      return await digestMessage(dataRoleOffer?.offer?.subject + dataRoleOffer?.offer?.body + dataRoleOffer?.offer?.agreementTerm)
    }
    if (dataRoleOffer) {
      getHash(dataRoleOffer).then(hash => {
        setOffersData({
          roleOffer: {
            offerId: dataRoleOffer?.offer?.id,
            offerHash: hash
          }
        })
      })
    }
    if (dataCyberSecurityPolicy) {
      setOffersData({ cyberSecurityPolicy: { agreementId: dataCyberSecurityPolicy?.agreement?.id } })
    }
    if (dataAssemblyDeliveryPolicy) {
      setOffersData({ assemblyDeliveryPolicy: { agreementId: dataAssemblyDeliveryPolicy?.agreement?.id } })
    }
  }, [dataRoleOffer, dataCyberSecurityPolicy, dataAssemblyDeliveryPolicy])

  const {
    data: employmentTypeFormData
  } = useGetEmploymentTypeForm({
    enabled: [
      employmentTypeStatusCodes.canCreate,
      employmentTypeStatusCodes.verification
    ].includes(employmentTypeFormStatus)
  })

  const { mutate, data, isLoading, isSuccess, isError } = useMutatePostEmploymentTypeForm()

  const handleUpdateForm = useCallback((isFirstLaunch = false) => {
    if (!isTouched && isFirstLaunch !== true) {
      setIsTouched(true)
    }
  }, [isTouched, profile?.personalData])

  const newLegalForm = useMemo(() => {
    return profile?.personalData?.legalForm && profile?.personalData?.legalForm === individual ? selfEmployed : individual
  }, [profile?.personalData?.legalForm])

  const fillForm = useCallback((formData) => {
    if (Object.keys(formData || {}).length) {
      const data = createFormDataFromApi({
        personalData: {
          [profileScreen.input.legalForm.name]: AppConfig.legalFormLabels[formData?.legalForm] || formData?.legalForm,
          [profileScreen.input.email.name]: formData?.email
        },
        bankDetails: {
          ...formData?.bankDetails,
          [profileScreen.input.bankDetailsPhoto.name]: formData?.bankDetailsPhoto
        },
        [profileScreen.input.egripPhoto.name]: formData?.egripPhoto
      })
      data[profileScreen.input.bankDetailsPhoto.name] = data.bankDetails.bankDetailsPhoto
      delete data.bankDetails.bankDetailsPhoto
      form.setFieldsValue(extState?.form ? extState.form : data)
      setTrigger(value => !value)
    }
  }, [form, extState])

  useEffect(() => {
    if (employmentTypeFormData) {
      fillForm(employmentTypeFormData?.data)
    } else if (newLegalForm && !employmentTypeFormData) {
      fillForm({ legalForm: newLegalForm })
    }
  }, [fillForm, newLegalForm, employmentTypeFormData])

  useEffect(() => {
    if (isError) {
      message.error('Ошибка отправки данных')
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      if (data?.errorMessage) {
        return message.error(data?.errorMessage || 'Ошибка отправки данных')
      }
      if (Object.keys(data?.profile || {}).length) {
        setPartnerProfile({ profile: data.profile })
      }
      message.success('Ваш запрос отправлен на проверку.')
      setExtState(PROFILE_EMPLOYMENT_TYPE_STATE_INIT)
    }
  }, [data, isSuccess, setExtState])

  useEffect(() => {
    if (Object.keys(backendFieldsErrors).length > 0) {
      form.setFields(Object.keys(backendFieldsErrors).map(key => ({
        name: key,
        errors: backendFieldsErrors[key]
      })))
    }
  }, [backendFieldsErrors])

  const onFinish = (values) => {
    if (!values.accept) return

    setBackendFieldsErrors({})
    if (!validateAllPhotosUploading(values)) {
      message.error(errors.documentsUploading)
      return
    }

    form.validateFields().then(values => {
      const data = createApiDataFromForm({
        ...values
      })
      mutate({
        data: {
          [profileScreen.input.legalForm.name]: AppConfig.legalFormLabelCodes[newLegalForm],
          ...(
            data?.personalData?.[profileScreen.input.email.name]?.length
              ? { [profileScreen.input.email.name]: data?.personalData?.[profileScreen.input.email.name] }
              : {}
          ),
          ...(profile?.bankDetails?.visible
            ? {
              bankDetails: {
                [profileScreen.input.bankAccount.name]: data?.bankDetails[profileScreen.input.bankAccount.name],
                [profileScreen.input.bankBik.name]: data?.bankDetails[profileScreen.input.bankBik.name],
                [profileScreen.input.bankName.name]: data?.bankDetails[profileScreen.input.bankName.name]
              }
            }
            : {}
          ),
          ...(profile?.bankDetailsPhoto?.visible
            ? {
              [profileScreen.input.bankDetailsPhoto.name]: data?.bankDetails[profileScreen.input.bankDetailsPhoto.name]
            }
            : {}
          ),
          ...(
            data?.[profileScreen.input.egripPhoto.name]?.length
              ? { [profileScreen.input.egripPhoto.name]: data[profileScreen.input.egripPhoto.name] }
              : {}
          )
        },
        offer: offersData?.roleOffer,
        assemblyAndDeliveryPolicyId: offersData?.assemblyDeliveryPolicy?.agreementId,
        cyberSecurityPolicyId: offersData?.cyberSecurityPolicy?.agreementId
      })
    })
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const handleBack = () => {
    setExtState(PROFILE_EMPLOYMENT_TYPE_STATE_INIT)
    history.goBack()
  }

  const handleGoOutFromPage = useCallback(() => {
    setExtState({
      form: form.getFieldsValue()
    })
  }, [form, setExtState])

  const handleUpdateImage = useCallback((item, info) => {
    form.setFieldsValue({ ...form.getFieldsValue(), [item.name]: info.fileList })
    setTrigger(value => !value)
  }, [form])

  const handleDeleteImage = useCallback((item) => {
    form.setFieldsValue({ ...form.getFieldsValue(), [item.name]: [] })
    setTrigger(value => !value)
  }, [form])

  const documentList = useMemo(() => {
    return newLegalForm === selfEmployed
      ? profile?.bankDetails?.visible ? [profileScreen.input.bankDetailsPhoto.name] : []
      : profile?.bankDetailsPhoto?.visible ? [profileScreen.input.bankDetailsPhoto.name, profileScreen.input.egripPhoto.name] : [profileScreen.input.egripPhoto.name]
  }, [newLegalForm, profile])

  const documentRequiredList = useMemo(() => {
    return newLegalForm === selfEmployed
      ? profile?.bankDetails?.visible ? { [profileScreen.input.bankDetailsPhoto.name]: true } : {}
      : profile?.bankDetailsPhoto?.visible
        ? { [profileScreen.input.bankDetailsPhoto.name]: true, [profileScreen.input.egripPhoto.name]: true }
        : { [profileScreen.input.egripPhoto.name]: true }
  }, [newLegalForm, profile])

  const renderTaxInfo = (isCreate = false) => {
    return (
      <Row className='myTaxAlert'>
        <Col span={24}>
          {
            isCreate
              ? <Title level={5}>Чтобы изменить статус — оформите самозанятость</Title>
              : <Title level={5}>У вас нет статуса самозанятого</Title>
          }
          {/* { */}
          {/*  !isCreate && ( */}
          {/*    <p>Для смены налогового статуса получите статус самозанятого, в Федеральной Налоговой Службе, для этого:</p> */}
          {/*  ) */}
          {/* } */}

          <ul>
            <li>Откройте сайт или приложение «Мой налог»</li>
            <li>Перейдите в «Мой налог»{nbsp}— на сайт или в приложение</li>
            <li>Дождитесь подтверждения регистрации</li>
            <li>Выберите Купер в качестве партнёра, чтобы получать выплаты</li>
            {/* <li>Перейдите в «Мой налог»{nbsp}— на сайт или в приложение</li> */}
            {/* <li>Зарегистрируйтесь используя любой удобный метод</li> */}
            {/* <li>Дождитесь сообщения о регистрации. Обычно это занимает не более 5 минут</li> */}
            {/* { */}
            {/*  !isCreate && ( */}
            {/*    <li><b>Повторите отправку анкеты</b></li> */}
            {/*  ) */}
            {/* } */}
          </ul>
          {/* <p>Для получения выплат выберите Купер в качестве партнёра в «Мой налог» (сайт или в приложении)</p> */}
          {/* <p>Перейдите в меню, раздел «Партнёры» и нажмите «Разрешить» в появившемся запросе от Купера</p> */}
        </Col>
        <Col span={24}>
          <Button
            block
            size='large'
            type='primary'
            onClick={handleMyTax}
            className='mb-3 nalog-btn'
          >
            <img src='/img/fns.png' />&nbsp; Перейти в «Мой налог»
          </Button>
          <Button
            block
            size='large'
            type='primary'
            onClick={() => setInfoStep(false)}
            className='mb-3'
          >
            Я уже самозанятый
          </Button>
        </Col>
      </Row>
    )
  }

  if (
    (profile?.statusCode && profile?.statusCode !== partnerStatusCodes.loginIssued) ||
    employmentTypeFormStatus === employmentTypeStatusCodes.none
  ) {
    history.push(PROFILE_MAIN_PAGE)
  }

  return (
    <Layout className='ProfileEditInfoWrapper'>
      {/* <MobileHeader /> */}

      <Spin spinning={!employmentTypeFormStatus || isLoading} size='large'>
        <Content className='Profile'>
          {/* <Title level={4} className='mt-3'>Смена налогового статуса с {profile?.personalData?.legalForm} на {newLegalForm}</Title> */}
          {/* <Row className='mt-3 mb-3'> */}
          {/*  <Button type='text' onClick={handleBack}> */}
          {/*    <LeftOutlined /> {profileContractScreen.button.back} */}
          {/*  </Button> */}
          {/* </Row> */}
          <Row className='mt-3 mb-3 arrow-left'>
            <Button type='text' onClick={handleBack}>
              <img src='/img/arrow-left.png' /> Смена налогового статуса
            </Button>
          </Row>

          {
            infoStep && newLegalForm === selfEmployed ? (
              <div>
                {renderTaxInfo(true)}
              </div>
            ) : (
              <div>

                {/* { */}
                {/*  employmentTypeFormStatus === employmentTypeStatusCodes.accepted && */}
                {/*    <Title level={5} className='mt-4'>Данные о смене налогового статуса уже приняты</Title> */}
                {/* } */}
                {employmentTypeFormStatus === employmentTypeStatusCodes.verification
                  ? <CheckingForm className='change-employmentType-checking' />
                  : (
                    <div>
                      <Form
                        {...formLayout}
                        name='basic'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onFieldsChange={handleUpdateForm}
                        form={form}
                        layout='vertical'
                        scrollToFirstError
                      >
                        <Row className='flex-column'>
                          <Col span={24}>
                            {
                              employmentTypeFormData?.declineReason &&
                                <Alert
                                  message={<Text type='secondary'>Причины отказа</Text>}
                                  description={(
                                    <>
                                      <div className='pre-line'>{employmentTypeFormData?.declineReason}</div>
                                      {/* {employmentTypeStatusCodes.declinedSmz === employmentTypeFormStatus && renderTaxInfo()} */}
                                    </>
                                  )}
                                  type='error'
                                  style={{ marginBottom: '1rem' }}
                                />
                            }
                            <ErrorBlock />
                            <Alert
                              message={`Сейчас вы ${profile?.personalData?.legalForm}`}
                              type='info'
                              showIcon
                              icon={<InfoCircleOutlined />}
                              className='mb-3'
                            />

                            <LegalFormItem disabled form={form} label='Налоговый статус' />

                            {newLegalForm === individual && <EmailFormItem />}

                            <BankDetails form={form} />

                            <Documents
                              hidePhotoDoc
                              showDocList
                              showHelpModal
                              trigger={trigger}
                              form={form}
                              required={documentRequiredList}
                              documents={documentList}
                              onUpdate={handleUpdateImage}
                              onDelete={handleDeleteImage}
                              onPhotoDoc={handleGoOutFromPage}
                            />

                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name='accept'
                              valuePropName='checked'
                              rules={[{
                                required: true,
                                transform: value => (value || undefined),
                                type: 'boolean',
                                message: offerScreen.offerText
                              }]}
                              className='offer'
                            >
                              <Checkbox disabled={!role}>
                                {offerScreen.input.accept.placeholder}:{' '}
                                <Link
                                  disabled={!role}
                                  to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offer}&role=${role}`}
                                  onClick={handleGoOutFromPage}
                                >
                                  {offerScreen.offerParts.roleOffer}
                                </Link>
                                {
                                  partnerRoles4MultiOffer.includes(role) && (
                                    <>
                                      ,{' '}
                                      <Link
                                        disabled={!role}
                                        to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.cyberSecurityPolicy}`}
                                        onClick={handleGoOutFromPage}
                                      >
                                        {offerScreen.offerParts.cyberSecurityPolicy}
                                      </Link>,{' '}
                                      <Link
                                        disabled={!role}
                                        to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.assemblyAndDeliveryPolicy}`}
                                        onClick={handleGoOutFromPage}
                                      >
                                        {offerScreen.offerParts.assemblyAndDeliveryPolicy}
                                      </Link>.
                                    </>
                                  )
                                }
                              </Checkbox>
                            </Form.Item>
                            <Button
                              htmlType='submit'
                              type='primary'
                              size='large'
                              className='mt-3'
                              block
                              loading={isLoading}
                              disabled={!isTouched && !extState?.form}
                            >
                              Отправить
                            </Button>
                            <Button
                              block
                              size='large'
                              className='mt-3 mb-3'
                              onClick={handleBack}
                              disabled={isLoading}
                            >
                              Отмена
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
              </div>
            )
          }
        </Content>
      </Spin>
    </Layout>
  )
}

export default FormEmploymentTypeScreen
