import React, { useEffect } from 'react'
import './SuccessStep.css'
import { Button, Col, message, Row, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

import ModalShopperDownload from '../../Success/ModalShopperDownload'
import { useGetPartnerProfile } from '../../../../api/react-query/partners'
import { PROFILE_CONTRACT_PAGE, PROFILE_MAIN_PAGE } from '../../../../routes'
import { useStores } from '../../../../Store/Store'
import {
  AppConfig,
  // cardStatusCodes,
  employmentTypeStatusCodes,
  fireBriefingStatusCodes,
  lmkStatusCodes,
  partnerFormStatusCodes
} from '../../../../AppConfig'
import { successScreen } from '../../../../translates'
// import { partnerRoles4MultiOffer } from '../../../../Constants'
// import ReissueCardRequest from '../../../Admin/Partner/EditModals/ReissueCardRequest'
// import { simpleReducer } from '../../../../helper'

const { Title, Text } = Typography
// const initialState = {
//   isSuccessReissueCard: false
// }

function SuccessStep () {
  const { partnerProfile } = useStores()
  const history = useHistory()
  // const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    isLoading: isLoadingPartner,
    isRefetching: isRefetchingPartner,
    isError: isErrorPartner,
    refetch: refetchPartner
  } = useGetPartnerProfile({
    enabled: false
  })

  useEffect(() => {
    if (isErrorPartner) {
      message.error(successScreen.error.message)
    }
  }, [isErrorPartner])

  const goToEditForm = async (searchParam) => {
    const res = await refetchPartner()
    if (res.isSuccess && res?.data?.partner) {
      partnerProfile.setPartnerProfile(res.data.partner)
      history.push({ pathname: PROFILE_MAIN_PAGE, search: searchParam })
    }
  }

  const handleEditInfo = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editInfo}`)
  }

  // const handleEditCard = () => {
  //   goToEditForm(`?action=${AppConfig.formScreenActions.editCard}`)
  // }

  const handleEditLmk = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editLmk}`)
  }

  const handleEditFireBriefing = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editFireBriefing}`)
  }

  const handleEditEmploymentType = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editEmploymentType}`)
  }

  // const handleOnSuccessReissue = () => {
  //   setState({ isSuccessReissueCard: true })
  // }

  return (
    <div className='success-step'>
      <Row justify='space-between' className='flex-column'>
        <Col>
          <Title level={3}>Проверка пройдена</Title>
          <Text>
            Теперь можно перейти в Шоппер и планировать первые слоты.
            Не забудьте загрузить фотографии действующей медкнижки
          </Text>
        </Col>
        <Col>
          <img src='/img/check-mark.png' width={150} />
        </Col>
        <Col>
          <ModalShopperDownload />

          <Button onClick={() => { history.push(PROFILE_CONTRACT_PAGE) }}>
            {successScreen.button.myContract}
          </Button>

          {
            /**
             *  ProfileFormStatus
             * none - не показываем кнопку/запретить показ страницы
             * canCreate - экран создания анкеты, может отправить анкету, если есть declineReason - показываем причину отклонения
             * verification - анкета на проверке
             */
            partnerProfile?.profile?.profileFormStatus !== partnerFormStatusCodes.none &&
              <Button onClick={handleEditInfo} loading={isLoadingPartner || isRefetchingPartner}>
                Изменить анкетные данные
              </Button>
          }

          {/* {[cardStatusCodes.canCreate, cardStatusCodes.declined].includes(partnerProfile?.profile?.cardFormStatus) && ( */}
          {/*  <Button onClick={handleEditCard} loading={isLoadingPartner || isRefetchingPartner}> */}
          {/*    Анкета банковской карты */}
          {/*  </Button> */}
          {/* )} */}

          {
            /**
             * LmkFormStatus
             * none - не показываем кнопку/запретить показ страницы
             * canCreate - экран создания анкеты, может отправить анкету, если есть declineReason - показываем причину отклонения
             * verification - анкета на проверке
             */
            partnerProfile?.profile?.lmkFormStatus !== lmkStatusCodes.none && (
              <Button onClick={handleEditLmk} loading={isLoadingPartner || isRefetchingPartner}>
                Загрузить медкнижку
              </Button>
            )
          }

          {
            /**
             *  FireBriefingStatus
             *  none - не показываем кнопку/запретить показ страницы
             *  canCreate - экран создания анкеты, может отправить анкету, если есть declineReason - показываем причину отклонения
             *  verification - анкета на проверке
             */
            partnerProfile?.profile?.fireBriefingFormStatus !== fireBriefingStatusCodes.none && (
              <Button onClick={handleEditFireBriefing} loading={isLoadingPartner || isRefetchingPartner}>
                Пожарный инструктаж
              </Button>
            )
          }

          {
            /**
             * EmploymentTypeFormStatus
             * none - не показываем кнопку/запретить показ страницы
             * canCreate - экран создания анкеты, может отправить анкету, если есть declineReason - показываем причину отклонения
             * verification - анкета на проверке
             */
            partnerProfile?.profile?.employmentTypeFormStatus !== employmentTypeStatusCodes.none && (
              <Button onClick={handleEditEmploymentType} loading={isLoadingPartner || isRefetchingPartner}>
                Смена налогового статуса
              </Button>
            )
          }

          {/* {partnerProfile?.profile?.allowCardReissue && !state.isSuccessReissueCard && ( */}
          {/*  <ReissueCardRequest forPartner partnerId={partnerProfile?.id} onSuccess={handleOnSuccessReissue} noBtnStyle /> */}
          {/* )} */}
        </Col>
      </Row>
    </div>
  )
}

export default SuccessStep
