import React from 'react'
import { useStores } from '../../../../Store/Store'
import { Button, Col, Row, Typography } from 'antd'
import './ErrorStep.css'
import { flowV2Steps } from '../ProfileV2'
import ErrorBlock from '../../Profile/ErrorBlock'
import { errors } from '../../../../translates'
import { getCorrectionStep } from '../../../../helper'

const { Title, Text } = Typography

export const errorStepLocalStorageName = 'errorStep'

function ErrorStep ({ setFlowStep }) {
  const { partnerProfile } = useStores()

  const handleClick = () => {
    const errorStep = getCorrectionStep(partnerProfile?.profile)
    localStorage.setItem(errorStepLocalStorageName, errorStep)
    return setFlowStep(errorStep)
  }

  return (
    <Row className='error-step'>
      <Col span={24}>
        <Title level={3}>Ошибка данных</Title>
        <Text>
          Отредактируйте разделы:
          <ul className='error-step-section-list'>
            {getCorrectionStep(partnerProfile?.profile) === flowV2Steps.passportStep && (
              <li>&mdash; Паспортные данные</li>
            )}
            {getCorrectionStep(partnerProfile?.profile) === flowV2Steps.innStep && (
              <li>&mdash; Персональные данные</li>
            )}
          </ul>
        </Text>
        <ErrorBlock title={errors.commentTitle} />
      </Col>
      <Col span={24}>
        <Button block onClick={handleClick}>Исправить ошибки</Button>
      </Col>
    </Row>
  )
}

export default ErrorStep
