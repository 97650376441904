import { Button, Col, Row, Typography } from 'antd'
import React from 'react'
import { nbsp } from '../../../Constants'
import { handleMyTax } from '../../../helper'
import './SelfEmployedStatus.css'

const { Title } = Typography

export default function ActiveSelfEmployedStatus ({ onClick, loading }) {
  const handleClick = () => {
    onClick?.()
  }

  return (
    <Row justify='space-between' className='selfEmployedStatus '>
      <Col span={24} className='info-block'>
        <Row>
          <Col span={24}>
            <Title level={3}>Остался последний шаг</Title>
            <p>Получите статус самозанятого</p>
            <p>
              Без статуса не получится выполнять заказы и оказывать услуги.
            </p>
            <ul>
              <li>Перейдите в «Мой налог»{nbsp}— на сайт или в приложение</li>
              <li>Зарегистрируйтесь используя любой удобный метод</li>
              <li>Дождитесь сообщения о регистрации. Обычно это занимает не более 5 минут</li>
              <li>Вернитесь на этот экран и нажмите «Подтвердить статус»</li>
            </ul>
          </Col>
          <Col span={24}>
            <img src='/img/pencil.png' className='pencil' />
          </Col>
        </Row>
      </Col>

      <Col span={24} className='btn-block'>
        <Button
          className='btn-my-tax margin-top-20'
          size='large'
          block
          onClick={handleMyTax}
        >
          <img src='/img/fns.png' className='mr-3' />Перейти в «Мой налог»
        </Button>
        <Button
          block
          ghost
          size='large'
          type='primary'
          className='margin-top-20 margin-bottom-20'
          onClick={handleClick}
          loading={loading}
        >
          Подтвердить статус
        </Button>
      </Col>
    </Row>
  )
}
