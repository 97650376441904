import { api, queryClient } from './api'
import { useQuery, useMutation } from 'react-query'

export const PARTNER_KEY = 'partner'
export const OFFER_KEY = 'offer'
export const PERSONAL_DATA_POLICY_KEY = 'personalDataPolicy'
export const ELECTRONIC_SIGNATURE_POLICY_KEY = 'electronicSignaturePolicy'
export const PARTNERS_SBER_MARKET_POLICY_KEY = 'partnersSbermarketPolicy'
export const CYBER_SECURITY_POLICY_KEY = 'cyberSecurityPolicy'
export const ASSEMBLY_DELIVERY_POLICY_KEY = 'assemblyAndDeliveryPolicy'
export const MEDICAL_ACCEPT_OFFER_KEY = 'lmkAgreement'
export const PERSONAL_ACT_KEY = 'act'
export const PARTNER_EDIT_INFO_KEY = 'form'
export const CLOTHING_SIZE_KEY = 'clothingSize'
export const EDIT_CARD_FORM_KEY = 'editCardForm'
export const EDIT_CARD_INIT_FORM_KEY = 'editCardFormInit'
export const EDIT_EMPLOYMENT_TYPE_FORM_KEY = 'editEmploymentTypeForm'
export const EDIT_FIRE_FORM_KEY = 'editFireForm'
export const EDIT_LMK_FORM_KEY = 'editLmkForm'
export const EDIT_LMK_INIT_FORM_KEY = 'editLmkFormInit'
export const EDIT_PROFILE_FORM_KEY = 'editProfileForm'
export const EDIT_PROFILE_INIT_FORM_KEY = 'editProfileFormInit'
export const SHOPPER_KEY = 'shopper'

const fetchPersonalDataPolicy = ({ queryKey }) => {
  const [/* key */, role] = queryKey
  const strRole = role ? `?role=${role}` : ''
  return api.get({ path: `${PARTNER_KEY}/${PERSONAL_DATA_POLICY_KEY}${strRole}` })
}
const fetchElectronicSignaturePolicy = () => {
  return api.get({ path: `${PARTNER_KEY}/${ELECTRONIC_SIGNATURE_POLICY_KEY}` })
}
const fetchPartnersSberMarket = () => {
  return api.get({ path: `${PARTNER_KEY}/${PARTNERS_SBER_MARKET_POLICY_KEY}` })
}
const fetchCyberSecurityPolicy = () => {
  return api.get({ path: `${PARTNER_KEY}/${CYBER_SECURITY_POLICY_KEY}` })
}
const fetchAssemblyDeliveryPolicy = () => {
  return api.get({ path: `${PARTNER_KEY}/${ASSEMBLY_DELIVERY_POLICY_KEY}` })
}
const fetchMedicalAcceptOffer = () => {
  return api.get({ path: `${PARTNER_KEY}/${MEDICAL_ACCEPT_OFFER_KEY}/template` })
}
const fetchPrivacyPolicyAccept = data => {
  return api.post({ path: `${PARTNER_KEY}/${PERSONAL_DATA_POLICY_KEY}/accept`, data })
}
const fetchElectronicSignaturePolicyAccept = data => {
  return api.post({ path: `${PARTNER_KEY}/${ELECTRONIC_SIGNATURE_POLICY_KEY}/accept`, data })
}
const fetchCyberSecurityPolicyAccept = data => {
  return api.post({ path: `${PARTNER_KEY}/${CYBER_SECURITY_POLICY_KEY}/accept`, data })
}
const fetchAssemblyDeliveryPolicyAccept = data => {
  return api.post({ path: `${PARTNER_KEY}/${ASSEMBLY_DELIVERY_POLICY_KEY}/accept`, data })
}
const fetchMedicalOfferAccept = data => {
  return api.post({ path: `${PARTNER_KEY}/${MEDICAL_ACCEPT_OFFER_KEY}/accept`, data })
}
const fetchPartnerAct = ({ queryKey }) => {
  const [/* key */, token] = queryKey
  return api.get({ path: `${PARTNER_KEY}/${PERSONAL_ACT_KEY}/${token}` })
}
const fetchPartnerActAccept = ({ token, actHash, templateData }) => {
  return api.post({ path: `${PARTNER_KEY}/${PERSONAL_ACT_KEY}/${token}/accept`, data: { actHash, templateData } })
}
const updateClothingSize = ({ sizeId, token }) => {
  return api.patch({ path: `${PARTNER_KEY}/${CLOTHING_SIZE_KEY}?token=${token}`, data: { sizeId } })
}
const createEditInfoRequest = data => {
  return api.post({ path: `${PARTNER_KEY}/profile/${PARTNER_EDIT_INFO_KEY}/create`, data })
}
const getOffer = ({ queryKey }) => {
  const [/* key */, role] = queryKey
  return api.get({ path: `${PARTNER_KEY}/${OFFER_KEY}${role ? '?role=' + role : ''}` })
}
const acceptOffer = (data) => {
  return api.post({ path: `${PARTNER_KEY}/${OFFER_KEY}/accept`, data })
}
const getEditCardForm = () => {
  return api.get({ path: `${PARTNER_KEY}/card/form` })
}
const getInitialEditCardForm = () => {
  return api.get({ path: `${PARTNER_KEY}/card/form/init` })
}
const getEditLmkForm = () => {
  return api.get({ path: `${PARTNER_KEY}/lmk/form` })
}
const getEditProfileForm = () => {
  return api.get({ path: `${PARTNER_KEY}/profile/form` })
}
const getFireBriefingForm = () => {
  return api.get({ path: `${PARTNER_KEY}/fireBriefing/form` })
}
const getEditEmploymentTypeForm = () => {
  return api.get({ path: `${PARTNER_KEY}/profile/employmentType/form` })
}
// const getInitialEditLmkForm = () => {
//   return api.get({ path: `${PARTNER_KEY}/lmk/form/init` })
// }
// const getInitialEditProfileForm = () => {
//   return api.get({ path: `${PARTNER_KEY}/profile/form/init` })
// }
const postEditCardForm = (data) => {
  return api.post({ path: `${PARTNER_KEY}/card/form`, data })
}
const postEditLmkForm = (data) => {
  return api.post({ path: `${PARTNER_KEY}/lmk/form`, data })
}
const postEditFireBriefingForm = (data) => {
  return api.post({ path: `${PARTNER_KEY}/fireBriefing/form`, data })
}
const postEditEmploymentTypeForm = (data) => {
  return api.post({ path: `${PARTNER_KEY}/profile/employmentType/form/create`, data })
}
const reissueCard = ({ data }) => {
  return api.post({ path: `${PARTNER_KEY}/card/reissue`, data })
}
const createMyTaxStatus = () => {
  return api.post({ path: `${PARTNER_KEY}/smz/create/send` })
}
const confirmMyTaxStatus = () => {
  return api.post({ path: `${PARTNER_KEY}/smz/status/confirm` })
}
const confirmMyTaxBind = () => {
  return api.post({ path: `${PARTNER_KEY}/smz/bind/confirm` })
}
const sendMyTaxBind = () => {
  return api.post({ path: `${PARTNER_KEY}/smz/bind/send` })
}
const getPartnerProfile = () => {
  return api.get({ path: `${PARTNER_KEY}/profile` })
}
const getShopperLinks = () => {
  return api.get({ path: `${PARTNER_KEY}/${SHOPPER_KEY}/app/links` })
}
const startTinkoffProfile = (data) => {
  return api.post({ path: `${PARTNER_KEY}/tinkoff/start`, data })
}
const consentTinkoffBankDetails = (data) => {
  return api.post({ path: `${PARTNER_KEY}/tinkoff/bankDetails/consent`, data })
}
const startOIDCProfile = (data) => {
  return api.post({ path: `${PARTNER_KEY}/oidc/start`, data })
}
const finishTinkoffProfile = (data) => {
  return api.post({ path: `${PARTNER_KEY}/tinkoff/finish`, data })
}
const finishOIDCProfile = (data) => {
  return api.post({ path: `${PARTNER_KEY}/oidc/finish`, data })
}
const startSberIdProfile = (data) => {
  return api.post({ path: `${PARTNER_KEY}/profile/sberid/start`, data })
}
const startPhonePartner = (data) => {
  return api.patch({ path: `${PARTNER_KEY}/phone/start`, data })
}
const finishPhonePartner = (data) => {
  return api.post({ path: `${PARTNER_KEY}/phone/finish`, data })
}
const fetchAuthPartner = (data) => {
  return api.post({ path: `${PARTNER_KEY}/auth`, data })
}
const fetchAuthVerifyPartner = (data) => {
  return api.post({ path: `${PARTNER_KEY}/auth/verify`, data })
}
const fetchPartnerProfile = (data) => {
  return api.patch({ path: `${PARTNER_KEY}/profile`, data })
}
const fetchPartnerProfileDraft = (data) => {
  return api.patch({ path: `${PARTNER_KEY}/profile/draft`, data })
}
const fetchPartnerProfileCheck = (data) => {
  return api.post({ path: `${PARTNER_KEY}/profile/check`, data })
}
const fetchPartnerNonCitizen = () => {
  return api.post({ path: `${PARTNER_KEY}/nonCitizen` })
}

export function useGetPersonalDataPolicyQuery (role, options) {
  return useQuery([PERSONAL_DATA_POLICY_KEY, role], fetchPersonalDataPolicy, options)
}
export function useGetElectronicSignaturePolicyQuery (options) {
  return useQuery([ELECTRONIC_SIGNATURE_POLICY_KEY], fetchElectronicSignaturePolicy, options)
}
export function useGetPartnersSberMarketQuery (options) {
  return useQuery([PARTNERS_SBER_MARKET_POLICY_KEY], fetchPartnersSberMarket, options)
}
export function useGetCyberSecurityPolicyQuery (options) {
  return useQuery([CYBER_SECURITY_POLICY_KEY], fetchCyberSecurityPolicy, options)
}
export function useGetAssemblyDeliveryPolicy (options) {
  return useQuery([ASSEMBLY_DELIVERY_POLICY_KEY], fetchAssemblyDeliveryPolicy, options)
}
export function useGetMedicalAcceptOffer (options) {
  return useQuery([MEDICAL_ACCEPT_OFFER_KEY], fetchMedicalAcceptOffer, options)
}
export function useMutatePrivacyPolicyAccept () {
  return useMutation(fetchPrivacyPolicyAccept)
}
export function useMutateElectronicSignaturePolicyAccept () {
  return useMutation(fetchElectronicSignaturePolicyAccept)
}
export function useMutateCyberSecurityPolicyAccept () {
  return useMutation(fetchCyberSecurityPolicyAccept)
}
export function useMutateAssemblyDeliveryPolicyAccept () {
  return useMutation(fetchAssemblyDeliveryPolicyAccept)
}
export function useMutateMedicalOfferAccept () {
  return useMutation(fetchMedicalOfferAccept)
}
export function useGetPartnerActQuery (token, options) {
  return useQuery([PERSONAL_ACT_KEY, token], fetchPartnerAct, options)
}
export function useMutatePartnerActAccept () {
  return useMutation(fetchPartnerActAccept, {
    onSuccess: () => {
      queryClient.refetchQueries(PERSONAL_ACT_KEY)
    }
  })
}
export function useMutateClothingSize () {
  return useMutation(updateClothingSize)
}
export function useMutateCreateEditInfoRequest () {
  return useMutation(createEditInfoRequest)
}

export function useGetOffer (role, options) {
  return useQuery([OFFER_KEY, role], getOffer, options)
}
export function useMutateAcceptOffer () {
  return useMutation(acceptOffer)
}
export function useGetEditCardForm (options) {
  return useQuery(EDIT_CARD_FORM_KEY, getEditCardForm, options)
}
export function useGetInitialEditCardForm (options) {
  return useQuery(EDIT_CARD_INIT_FORM_KEY, getInitialEditCardForm, options)
}
export function useGetLmkForm (options) {
  return useQuery(EDIT_LMK_FORM_KEY, getEditLmkForm, options)
}
export function useGetEditProfileForm (options) {
  return useQuery(EDIT_PROFILE_FORM_KEY, getEditProfileForm, options)
}
export function useGetFireBriefingForm (options) {
  return useQuery(EDIT_FIRE_FORM_KEY, getFireBriefingForm, options)
}
export function useGetEmploymentTypeForm (options) {
  return useQuery(EDIT_EMPLOYMENT_TYPE_FORM_KEY, getEditEmploymentTypeForm, options)
}
// export function useGetInitialEditLmkForm (options) {
//   return useQuery(EDIT_LMK_INIT_FORM_KEY, getInitialEditLmkForm, options)
// }
// export function useGetInitialEditProfileForm (options) {
//   return useQuery(EDIT_PROFILE_INIT_FORM_KEY, getInitialEditProfileForm, options)
// }
export function useMutatePostCardForm () {
  return useMutation(postEditCardForm)
}
export function useMutatePostLmkForm () {
  return useMutation(postEditLmkForm)
}
export function useMutatePostFireBriefingForm () {
  return useMutation(postEditFireBriefingForm)
}
export function useMutatePostEmploymentTypeForm () {
  return useMutation(postEditEmploymentTypeForm)
}
export function useMutateReissueCard () {
  return useMutation(reissueCard)
}
export function useMutateCreateMyTaxStatus () {
  return useMutation(createMyTaxStatus)
}
export function useMutateConfirmMyTaxStatus () {
  return useMutation(confirmMyTaxStatus)
}
export function useMutateConfirmMyTaxBind () {
  return useMutation(confirmMyTaxBind)
}
export function useMutateSendMyTaxBind () {
  return useMutation(sendMyTaxBind)
}
export function useGetPartnerProfile (options) {
  return useQuery(PARTNER_KEY, getPartnerProfile, options)
}
export function useGetShopperLinks (options) {
  return useQuery(SHOPPER_KEY, getShopperLinks, options)
}
export function useMutateStartTinkoffPartner () {
  return useMutation(startTinkoffProfile)
}
export function useMutateConsentTinkoffBankDetails () {
  return useMutation(consentTinkoffBankDetails)
}
export function useMutateStartIODCPartner () {
  return useMutation(startOIDCProfile)
}
export function useMutateFinishTinkoffPartner () {
  return useMutation(finishTinkoffProfile)
}
export function useMutateFinishIODCPartner () {
  return useMutation(finishOIDCProfile)
}
export function useMutateStartSberIdPartner () {
  return useMutation(startSberIdProfile)
}
export function useMutateStartPhonePartner () {
  return useMutation(startPhonePartner)
}
export function useMutateFinishPhonePartner () {
  return useMutation(finishPhonePartner)
}
export function useMutateAuthPartner () {
  return useMutation(fetchAuthPartner)
}
export function useMutateAuthVerifyPartner () {
  return useMutation(fetchAuthVerifyPartner)
}
export function useMutatePartnerProfile () {
  return useMutation(fetchPartnerProfile, {
    onSuccess: () => {
      queryClient.refetchQueries(PARTNER_KEY)
      queryClient.invalidateQueries(PARTNER_KEY)
    }
  })
}
export function useMutatePartnerProfileDraft () {
  return useMutation(fetchPartnerProfileDraft)
}
export function useMutatePartnerProfileCheck () {
  return useMutation(fetchPartnerProfileCheck)
}
export function useMutatePartnerNonCitizen () {
  return useMutation(fetchPartnerNonCitizen)
}
