import React, { useCallback } from 'react'
import { Button, Col, Row, Typography } from 'antd'

const { Title, Text } = Typography

export default function CheckingData ({ subText = '', className = '' }) {
  const handleReload = useCallback(() => {
    location.reload()
  }, [])

  return (
    <Row className={`flex-space-between ${className} CheckingData`}>
      <Col>
        <Title level={3}>Проверяем данные</Title>
        {subText ||
          <div className='info'>
            {/* <Text>Запрос отправлен на проверку.</Text> */}
            <Text>Обычно это занимает около 5 мин.</Text>
            <Text>Обновите страницу чуть позже или дождитесь СМС — пришлём его, как закончим.</Text>
          </div>}
      </Col>
      <Col span={24}>
        <img src='/img/clock.png' className='processing' />
      </Col>
      <Col span={24} className='btn-block'>
        <Button
          block
          size='large'
          type='primary'
          className='margin-bottom-20 ant-btn-success'
          onClick={handleReload}
        >
          Обновить
        </Button>
      </Col>
    </Row>
  )
}
