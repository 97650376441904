import React, { useEffect, useLayoutEffect, useReducer, useRef } from 'react'
import { Button, Form, Input, message, Spin, Typography } from 'antd'
import InputMask from 'react-input-mask'

import { patterns, simpleReducer } from '../../../helper'
import { authScreen } from '../../../translates'
import './ModalChangePhone.css'
import { useMutateFinishPhonePartner, useMutateStartPhonePartner } from '../../../api/react-query/partners'
import { AppConfig } from '../../../AppConfig'
import ModalDrawerPopup from '../../Common/ModalDrawerPopup/ModalDrawerPopup'

const { Title, Text } = Typography

const inputModes = {
  inputPhone: 'inputPhone',
  inputSms: 'inputSms'
}

const buttonLabels = {
  [inputModes.inputPhone]: 'Получить код',
  [inputModes.inputSms]: 'Подтвердить'
}

const initialState = {
  visible: false,
  inputMode: inputModes.inputPhone,
  phoneErrorCode: null
}

function ModalChangePhone ({ onSuccess }) {
  const [state, setState] = useReducer(simpleReducer, initialState)
  const [form] = Form.useForm()
  const inputPhoneRef = useRef()
  const inputSmsRef = useRef()

  const {
    mutate: startPhone,
    data: dataStartPhone,
    isSuccess: isSuccessStartPhone,
    isError: isErrorStartPhone,
    isLoading: isLoadingStartPhone
  } = useMutateStartPhonePartner()

  const validatePhone = (rule, value) => {
    const phonePattern = patterns.phone
    if (value.includes('_') || !value) {
      // пока в маске не введены все цифры не валидируем и не показываем ошибку
      return Promise.resolve()
    }
    return value && phonePattern.test(value.replace(/[ _-]/g, '')) ? Promise.resolve() : Promise.reject(authScreen.input.phoneNumber.message)
  }

  useEffect(() => {
    if (isSuccessStartPhone && dataStartPhone?.isSuccess) {
      message.success('Смс с кодом отправлено')
      setState({ inputMode: inputModes.inputSms })
    } else if (isErrorStartPhone || dataStartPhone?.errorMessage) {
      message.error(dataStartPhone?.errorMessage || 'Ошибка отправки данных для смс')
      if (dataStartPhone?.errorCode === AppConfig.phoneEditErrorCodes.PhoneDuplicate) {
        setState({ phoneErrorCode: 'Номер уже используется' })
        console.log('dataStartPhone', dataStartPhone)
      }
    }
  }, [isSuccessStartPhone, isErrorStartPhone, dataStartPhone])

  const {
    mutate: finishPhone,
    data: dataFinishPhone,
    isSuccess: isSuccessFinishPhone,
    isError: isErrorFinishPhone,
    isLoading: isLoadingFinishPhone
  } = useMutateFinishPhonePartner()

  useEffect(() => {
    if (isSuccessFinishPhone && dataFinishPhone?.isSuccess) {
      message.success('Номер изменен')
      setState({ visible: false })
      onSuccess?.(dataFinishPhone?.mobilePhone)
    } else if (isErrorFinishPhone || dataFinishPhone?.errorMessage) {
      message.error(dataFinishPhone?.errorMessage || 'Ошибка отправки кода из смс')
    }
  }, [isSuccessFinishPhone, isErrorFinishPhone, dataFinishPhone])

  const handleOpen = (event) => {
    event.preventDefault()
    setState({ visible: true })
  }

  const handleClose = () => {
    setState({ visible: false })
  }

  const handleChangeForm = () => {
    setState({ phoneErrorCode: null })
  }

  const handleSubmit = (values) => {
    if (state.inputMode === inputModes.inputPhone) {
      // валидируем маску - все ли цифры введены
      if (form.getFieldValue('mobilePhone').includes('_') || !form.getFieldValue('mobilePhone')) {
        return form.setFields([{
          name: 'mobilePhone',
          errors: [authScreen.input.phoneNumber.message]
        }])
      }
      startPhone({
        mobilePhone: values?.mobilePhone?.trim()
      })
    }
    if (state.inputMode === inputModes.inputSms) {
      finishPhone({
        code: values?.code?.trim()
      })
    }
  }

  useEffect(() => {
    setState({
      ...initialState,
      visible: state.visible
    })
    form.resetFields()
  }, [state.visible])

  useLayoutEffect(() => {
    if (state.visible) {
      if (state.inputMode === inputModes.inputSms) {
        setTimeout(() => {
          inputSmsRef?.current?.focus()
        }, 1)
      }
      if (state.inputMode === inputModes.inputPhone) {
        setTimeout(() => {
          inputPhoneRef?.current?.focus()
        }, 1)
      }
    }
  }, [state.isModalPhone, state.inputMode])

  return (
    <div className='buttonChangePhone'>
      <a href='' onClick={handleOpen}>Изменить номер</a>
      <ModalDrawerPopup
        className='modalChangePhone'
        isOpen={state.visible}
        onClose={handleClose}
        drawerHeight='auto'
        // centered
        // footer={null}
        closable
        destroyOnClose
        maskClosable
      >
        <Title level={4} style={{ textAlign: 'center' }}>Смена номера</Title>
        {
          state.inputMode === inputModes.inputPhone && (
            <div className='message'>Укажите номер, с которого будете принимать заказы</div>
          )
        }

        {
          state.inputMode === inputModes.inputSms && (
            <Text>Введите код из СМС</Text>
          )
        }
        <Spin spinning={isLoadingStartPhone || isLoadingFinishPhone}>
          <Form
            form={form}
            onFinish={handleSubmit}
            onFieldsChange={handleChangeForm}
          >
            {
              state.inputMode === inputModes.inputPhone && (
                <Form.Item
                  className='phone-input'
                  name='mobilePhone'
                  rules={[{ required: true, message: authScreen.input.phoneNumber.message, validator: validatePhone }]}
                  help={state.phoneErrorCode}
                  validateStatus={state.phoneErrorCode ? 'error' : null}
                >
                  <InputMask
                    mask='+7 999 999-99-99'
                    // disabled={state.timeState.time || isDisabledPhone}
                    children={() => (
                      <Input
                        ref={inputPhoneRef}
                        size='large'
                        type='tel'
                        placeholder={authScreen.input.phoneNumber.placeholder}
                      />)}
                  />
                </Form.Item>

              )
            }
            {
              state.inputMode === inputModes.inputSms && (
                <Form.Item
                  className='phone-input'
                  name='code'
                  rules={[{ required: true, message: authScreen.input.smsCode.message }]}
                >
                  <Input
                    ref={inputSmsRef}
                    className='noScrollNumber'
                    size='large'
                    type='number'
                    placeholder={authScreen.input.smsCode.placeholder}
                  />
                </Form.Item>
              )
            }
            <Form.Item className='phone-btn'>
              <Button
                className='buttonSend'
                block
                size='large'
                type='primary'
                htmlType='submit'
              >
                {buttonLabels[state.inputMode]}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </ModalDrawerPopup>
    </div>
  )
}

export default ModalChangePhone
