import React, { useCallback, useEffect, useMemo } from 'react'
import { Alert, Button, Col, Layout, message, Row, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'

import './SuccessStyle.css'
// import { useDataApi } from '../../../api/useDataApi'
import { useStores } from '../../../Store/Store'
import { PROFILE_CONTRACT_PAGE, PROFILE_MAIN_PAGE } from '../../../routes'
import {
  AppConfig,
  // cardStatusCodes,
  employmentTypeStatusCodes,
  fireBriefingStatusCodes,
  innDetailsNameStatusCodes,
  innDetailsStatusCodes,
  lmkStatusCodes,
  partnerFormStatusCodes,
  partnerStatusCodes
} from '../../../AppConfig'
import { successScreen } from '../../../translates'
// import { getHeaders } from '../../../api/react-query/api'
// import ReissueCardRequest from '../../Admin/Partner/EditModals/ReissueCardRequest'
import { useGetPartnerProfile } from '../../../api/react-query/partners'
import MyTaxApp from '../MyTaxApp/MyTaxApp'
// import { partnerRoles4MultiOffer } from '../../../Constants'
import InnPhotoDescriptionForm from '../Profile/InnPhotoDescriptionForm'
import ModalShopperDownload from './ModalShopperDownload'
import CheckingData from '../CheckingData'

const { Content } = Layout
const { Paragraph, Title, Text } = Typography
const { smzStatusCodes, smzErrorCodes, smzRequiredActions } = AppConfig

const Success = () => {
  const { partnerProfile } = useStores()
  const history = useHistory()
  const profile = partnerProfile.profile
  // const [{ data, isError, isLoading }, doFetch] = useDataApi({})
  // const [isSuccessReissueCard, setIsSuccessReissueCard] = useState(false)

  const showShopperMessage = useMemo(() => {
    return !AppConfig.partnerAdditionalRoles.includes(profile?.personalData?.roleCode)
  }, [profile?.personalData?.roleCode])

  // useEffect(() => {
  //   if (!data?.isSuccess) return
  //   if (isError) {
  //     return message.error(successScreen.error.message)
  //   }
  //   partnerProfile.setPartnerProfile(data)
  // }, [data, isError])

  // const handleClick = () => {
  //   doFetch('partner/shopper/registered', {
  //     method: 'POST',
  //     headers: getHeaders()
  //   })
  // }

  const {
    isLoading: isLoadingPartner,
    isRefetching: isRefetchingPartner,
    isError: isErrorPartner,
    refetch: refetchPartner
  } = useGetPartnerProfile({
    enabled: false
  })

  useEffect(() => {
    if (isErrorPartner) {
      message.error(successScreen.error.message)
    }
  }, [isErrorPartner])

  // const handleOnSuccessReissue = () => {
  //   setIsSuccessReissueCard(true)
  // }

  const handleReload = useCallback(() => {
    location.reload()
  }, [])

  const renderMessage = () => {
    if ((profile?.statusCode === partnerStatusCodes.loginIssued || profile?.statusCode === partnerStatusCodes.blocked) && profile?.payoutSuspended) {
      return (
        <Col className='full-width'>
          <p>{successScreen.prefixTitle}</p>
          <p>{successScreen.payoutSuspendedTitle}</p>
        </Col>
      )
    }

    switch (profile?.statusCode) {
      case partnerStatusCodes.verification: return (
        <Col className='full-width'>
          {!isShowInnForm && !isShowMyTax && (<CheckingData />)}
        </Col>
      )

      case partnerStatusCodes.loginNotFound: return (
        <>
          {
            showShopperMessage && (
              <Col>
                <Paragraph>
                  <Title level={3}>{successScreen.loginNotFound}</Title>
                  {
                    profile?.declineReason && (
                      <Alert
                        description={profile?.declineReason}
                        type='error'
                      />
                    )
                  }
                </Paragraph>
              </Col>
            )
          }
          <Col>
            <img src='/img/alert-yellow.png' className='processing alert' />
          </Col>
          <Col>
            &nbsp;
          </Col>
          {/* <Spin spinning={isLoading} size='large'> */}
          {/*  <Button */}
          {/*    ghost */}
          {/*    size='large' */}
          {/*    type='primary' */}
          {/*    onClick={handleClick} */}
          {/*    className='margin-top-20' */}
          {/*  > */}
          {/*    {successScreen.button.register} */}
          {/*  </Button> */}
          {/* </Spin> */}
        </>
      )

      case partnerStatusCodes.loginIssued: return (
        <>
          {showShopperMessage ? (
            <Col>
              <Text>{successScreen.shopperText}</Text>
            </Col>
          ) : (
            <Col>
              <Title level={2}>
                  &nbsp;
              </Title>
            </Col>
          )}
          <Col className='full-width'>
            <img src='/img/check-mark.png' width={150} className='check-mark' />
          </Col>
        </>
      )

      case partnerStatusCodes.rejected: return (
        <Col span={24}>
          <Paragraph>{successScreen.prefixTitle}</Paragraph>
          <Paragraph>{successScreen.title}</Paragraph>
        </Col>
      )

      // 'Проверен' === partnerStatusCodes.verified
      default: return (
        <Row className='flex-space-between height-without-header'>
          <Col>
            <Text>{successScreen.prefixTitle}</Text>
            {
              showShopperMessage && (
                <Text>
                  <p>{successScreen.verified}</p>
                </Text>
              )
            }
          </Col>
          <Col span={24}>
            <img src='/img/clock.png' className='processing' />
          </Col>
          <Col span={24} className='btn-block'>
            <Button
              block
              size='large'
              type='primary'
              className='margin-bottom-20 ant-btn-success'
              onClick={handleReload}
            >
              Обновить
            </Button>
          </Col>
        </Row>
      )
    }
  }

  const goToEditForm = async (searchParam) => {
    const res = await refetchPartner()
    if (res.isSuccess && res?.data?.partner) {
      partnerProfile.setPartnerProfile(res.data.partner)
      history.push({ pathname: PROFILE_MAIN_PAGE, search: searchParam })
    }
  }

  const handleEditInfo = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editInfo}`)
  }

  // const handleEditCard = () => {
  //   goToEditForm(`?action=${AppConfig.formScreenActions.editCard}`)
  // }

  const handleEditLmk = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editLmk}`)
  }

  const handleEditFireBriefing = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editFireBriefing}`)
  }

  const handleEditEmploymentType = () => {
    goToEditForm(`?action=${AppConfig.formScreenActions.editEmploymentType}`)
  }

  const isShowInnForm = useMemo(() => {
    return profile?.statusCode === partnerStatusCodes.verification &&
      profile?.innDetails?.nameStatus === innDetailsNameStatusCodes.different &&
      profile?.innDetails?.status === innDetailsStatusCodes.declined &&
      profile?.innDetails?.visible
  }, [profile])

  const isShowMyTax = useMemo(() => {
    return [
      partnerStatusCodes.verification, partnerStatusCodes.verified
    ].includes(profile?.statusCode) &&
      (
        (profile?.smz?.errorCode &&
          ![smzErrorCodes.partnerUnregistered, smzErrorCodes.requestNotProcessed].includes(profile?.smz?.errorCode)
        ) ||
        profile?.smz?.requiredAction === smzRequiredActions.ConfirmSmz ||
        profile?.smz?.statusCode === smzStatusCodes.bindingSending ||
        [smzRequiredActions.ReaffirmBind, smzRequiredActions.ConfirmBind].includes(profile?.smz?.requiredAction)
      )
  }, [profile])

  const renderTitle = () => {
    if ((profile?.statusCode === partnerStatusCodes.loginIssued || profile?.statusCode === partnerStatusCodes.blocked) && profile?.payoutSuspended) {
      return 'Заполнение анкеты'
    }

    switch (profile?.statusCode) {
      case partnerStatusCodes.loginNotFound:
      case partnerStatusCodes.verification: return ''

      case partnerStatusCodes.loginIssued: return 'Данные проверены'

      case partnerStatusCodes.rejected: return 'Заполнение анкеты'

      // 'Проверен' === partnerStatusCodes.verified
      default: return 'Данные проверены'
    }
  }

  return (
    <>
      {!!renderTitle() && <Title level={4}>{renderTitle()}</Title>}
      <Content className='Success'>
        {
          isShowMyTax &&
          [
            partnerStatusCodes.verification, partnerStatusCodes.verified
          ].includes(profile?.statusCode)
            ? (
              <MyTaxApp profile={profile} />
            ) : (
              <Row justify='space-between' className='flex-column'>
                {
                  isShowInnForm && (
                    <Col className='full-width'>
                      <InnPhotoDescriptionForm profile={profile} />
                    </Col>
                  )
                }
                {renderMessage()}
                <Col>
                  {
                    profile?.statusCode === partnerStatusCodes.loginIssued &&
                      <>
                        <ModalShopperDownload />
                        <Button
                          ghost
                          block
                          size='large'
                          type='primary'
                          onClick={() => { history.push(PROFILE_CONTRACT_PAGE) }}
                          className='margin-top-20 margin-bottom-20'
                        >
                          {successScreen.button.myContract}
                        </Button>

                        {
                          /**
                           *  ProfileFormStatus
                           * none - не показываем кнопку/запретить показ страницы
                           * canCreate - экран создания анкеты, может отправить анкету, если есть declineReason - показываем причину отклонения
                           * verification - анкета на проверке
                           */
                          profile?.profileFormStatus !== partnerFormStatusCodes.none &&
                            <Button
                              block
                              ghost
                              size='large' type='primary'
                              className='margin-bottom-20'
                              onClick={handleEditInfo}
                              loading={isLoadingPartner || isRefetchingPartner}
                            >
                              Изменить анкетные данные
                            </Button>
                        }

                        {/* { */}
                        {/*  [cardStatusCodes.canCreate, cardStatusCodes.declined].includes(profile?.cardFormStatus) && */}
                        {/*    <Button */}
                        {/*      ghost */}
                        {/*      block */}
                        {/*      size='large' */}
                        {/*      type='primary' */}
                        {/*      className='margin-bottom-20' */}
                        {/*      onClick={handleEditCard} */}
                        {/*      loading={isLoadingPartner || isRefetchingPartner} */}
                        {/*    > */}
                        {/*      Анкета банковской карты */}
                        {/*    </Button> */}
                        {/* } */}
                        {
                          /**
                           * LmkFormStatus
                           * none - не показываем кнопку/запретить показ страницы
                           * canCreate - экран создания анкеты, может отправить анкету, если есть declineReason - показываем причину отклонения
                           * verification - анкета на проверке
                           */
                          profile?.lmkFormStatus !== lmkStatusCodes.none &&
                            <Button
                              ghost
                              size='large'
                              type='primary'
                              block
                              className='margin-bottom-20'
                              onClick={handleEditLmk}
                              loading={isLoadingPartner || isRefetchingPartner}
                            >
                              Изменить данные ЛМК
                            </Button>
                        }
                        {
                          /**
                           *  FireBriefingStatus
                           *  none - не показываем кнопку/запретить показ страницы
                           *  canCreate - экран создания анкеты, может отправить анкету, если есть declineReason - показываем причину отклонения
                           *  verification - анкета на проверке
                           */
                          profile?.fireBriefingFormStatus !== fireBriefingStatusCodes.none &&
                            <Button
                              ghost
                              size='large'
                              block
                              type='primary'
                              className='margin-bottom-20'
                              onClick={handleEditFireBriefing}
                              loading={isLoadingPartner || isRefetchingPartner}
                            >
                              Пожарный инструктаж
                            </Button>
                        }

                        {
                          /**
                           * EmploymentTypeFormStatus
                           * none - не показываем кнопку/запретить показ страницы
                           * canCreate - экран создания анкеты, может отправить анкету, если есть declineReason - показываем причину отклонения
                           * verification - анкета на проверке
                           */
                          profile?.employmentTypeFormStatus !== employmentTypeStatusCodes.none &&
                            <Button
                              ghost
                              size='large'
                              block
                              type='primary'
                              className='margin-bottom-20'
                              onClick={handleEditEmploymentType}
                              loading={isLoadingPartner || isRefetchingPartner}
                            >
                              Смена налогового статуса
                            </Button>
                        }

                        {/* {profile?.allowCardReissue && !isSuccessReissueCard && */}
                        {/*  <div className='margin-bottom-20'> */}
                        {/*    <ReissueCardRequest forPartner partnerId={partnerProfile?.id} onSuccess={handleOnSuccessReissue} /> */}
                        {/*  </div>} */}
                      </>
                  }
                </Col>
              </Row>
            )
        }

      </Content>
    </>
  )
}
export default observer(Success)
