import React, { useCallback, useEffect, useReducer } from 'react'
import { useParams, withRouter, generatePath } from 'react-router-dom'
import { message, Table, Drawer, Spin, Button, Space, ConfigProvider, Collapse, Typography } from 'antd'
import ruRu from 'antd/es/locale/ru_RU'
import { useObserver } from 'mobx-react'

import User from './User'
import AutoCompleteFilter from '../../../components/AutoCompleteFilter/AutoCompleteFilter'
import { ADMIN_USERS_PAGE, ADMIN_USER_PAGE } from '../../../routes'
import { isProd, simpleReducer } from '../../../helper'
import './Users.css'
import { useUsersSearch, useGetUserGroupsQuery } from '../../../api/react-query/users'
import { twoMinutes, emptyFilter } from '../../../Constants'
import { useCities } from '../../../hooks'
import getColumnSearchProps from '../../../components/SearchFilter/SearchFilter'

const { Panel } = Collapse
const { Text } = Typography

function Users ({ history }) {
  const initState = {
    usersList: {
      users: [],
      pageSize: 20,
      pageNumber: 1,
      resultsTotal: 0,
      isSuccess: true,
      errorMessage: '',
      ...(JSON.parse(localStorage.getItem('usersRequest') || '{}'))
    },
    groups: [],
    subscriptions: [],
    request: {},
    activeCollapse: []
  }
  const [state, setState] = useReducer(simpleReducer, initState)
  const { id } = useParams()

  const { data: cities } = useCities()

  const {
    data,
    isError,
    isSuccess,
    isLoading
  } = useUsersSearch({
    // pagination
    pageSize: state.usersList.pageSize,
    pageNumber: state.usersList.pageNumber,
    // sorting
    sortBy: state.usersList.orderBy,
    sortDir: state.usersList.orderDir,
    // filtering
    cities: state.usersList.filter?.cities,
    name: state.usersList.filter?.name,
    userGroup: state.usersList?.filter?.groupName
  }, {
    staleTime: twoMinutes
  })

  useEffect(() => {
    if (isSuccess) {
      setState({
        usersList: {
          ...(JSON.parse(localStorage.getItem('usersRequest') || '{}')),
          ...data
        }
      })
    }
  }, [isSuccess, data])

  useEffect(() => {
    if (isError) {
      console.error(data)
      message.error('Ошибка получения списка пользователей')
    }
  }, [isError, data])

  const {
    data: userGroupsData,
    isError: isErrorUserGroups,
    isSuccess: isSuccessUserGroups
  } = useGetUserGroupsQuery(
    {
      staleTime: twoMinutes
    })

  useEffect(() => {
    if (isErrorUserGroups) {
      message.error('Ошибка доступа к данным')
    }
  }, [isErrorUserGroups])
  useEffect(() => {
    if (isSuccessUserGroups) {
      if (userGroupsData.errorMessage) {
        return message.error(userGroupsData.errorMessage)
      }
      setState({ groups: userGroupsData?.groups || [] })
    }
  }, [isSuccessUserGroups, userGroupsData])

  const renderDropdown = ({ list, key }) => (
    <div
      className='clickable-cell-table'
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {list?.length > 1 ? (
        <Collapse
          ghost
          activeKey={state.activeCollapse}
          onChange={() => setState({ activeCollapse: state.activeCollapse[0] === key ? [] : [key] })}
        >
          <Panel header={list?.join(', ')} key={key}>
            <Text className='pre-line'>{list?.join('\n')}</Text>
          </Panel>
        </Collapse>)
        : <Text className='pre-line'>{list?.join('\n')}</Text>}
    </div>
  )

  const columns = [
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', 'по ФИО'),
      sorter: true,
      sortOrder: state.usersList?.orderBy === 'name' ? state.usersList?.orderDir + 'end' : false,
      filteredValue: state.usersList?.filter?.name ? [state.usersList?.filter?.name] : null
    },
    {
      title: 'Группа',
      dataIndex: 'groupName',
      key: 'groupName',
      render: item => state.groups.find(e => e.id === item)?.name,
      filters: state.groups.map(s => ({ text: s.name, value: s.name })),
      filteredValue: state.usersList?.filter?.groupName || null
    },
    {
      title: 'Город',
      dataIndex: ['cities'],
      key: 'cities',
      ellipsis: {
        showTitle: false
      },
      render: (cities, user) => {
        const list = cities.map(item => item.name)
        const key = user.id + '-cities'
        return renderDropdown({ list, key })
      },
      filterDropdown: useCallback(
        props => (
          <AutoCompleteFilter
            values={[
              { ...emptyFilter, value: '' },
              ...(cities.map(c => ({ text: c.name, value: c.name })) || [])
            ]}
            {...props}
          />
        ),
        [cities]
      ),
      filteredValue: state.usersList?.filter?.cities || null
    }
  ]

  const pagination = {
    current: state.usersList.pageNumber,
    total: state.usersList.resultsTotal,
    pageSize: state.usersList.pageSize
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const filterArrType = ['cities', 'groupName']
    let orderDir
    if (sorter?.order) {
      orderDir = sorter.order === 'ascend' ? 'asc' : 'desc'
    }
    const usersList = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      filter: Object.entries(filters || {})
        .reduce((r, c) => ({
          ...r,
          [c[0]]: c[1] ? (filterArrType.includes(c[0]) ? c[1] : c[1][0]) : undefined
        }), {}),
      orderBy: sorter.field,
      orderDir
    }
    setState({
      usersList
    })
    localStorage.setItem('usersRequest', JSON.stringify(usersList))
  }

  const handleSelectRow = (record) => ({
    onClick: () => {
      history.replace(generatePath(ADMIN_USER_PAGE, { id: record.id }))
    }
  })
  const handleAddUser = () => {
    history.replace(generatePath(ADMIN_USER_PAGE, { id: 'new' }))
  }

  return useObserver(() =>
    <div className='Users'>
      <Spin spinning={isLoading} size='large'>
        {!isProd() && (
          <Space style={{ marginBottom: 16 }}>
            <Button onClick={handleAddUser}>
              Добавить пользователя
            </Button>
          </Space>
        )}
        <ConfigProvider locale={ruRu}>
          <Table
            columns={columns}
            loadind={isLoading}
            pagination={pagination}
            rowKey={record => record.id}
            dataSource={state.usersList.users}
            onChange={handleTableChange}
            onRow={handleSelectRow}
          />
        </ConfigProvider>
      </Spin>
      {
        !!id &&
          <Drawer
            width={800}
            placement='right'
            closable={false}
            onClose={() => {
              history.replace(ADMIN_USERS_PAGE)
            }}
            visible={!!id}
            className='AdminDrawer'
          >
            <User groups={state.groups} />
          </Drawer>
      }
    </div>
  )
}

export default withRouter(Users)
