import { Button, Col, Row, Typography } from 'antd'
import React from 'react'
import { nbsp } from '../../../Constants'
import { handleMyTax } from '../../../helper'
import './SelfEmployedStatus.css'

const { Title } = Typography

export default function InActiveSelfEmployedStatus ({ onClick, loading }) {
  const handleClick = () => {
    onClick?.()
  }
  return (
    <Row justify='space-between' className='selfEmployedStatus'>
      <Col span={24} className='info-block'>
        <Row>
          <Col span={24}>
            <Title level={3}>Остался последний шаг</Title>

            <p>Подключите партнёрство в{nbsp}«Мой{nbsp}налог»</p>
            <p>
              Мы отправили вам запрос на партнёрство в Мой налог.
            </p>
            <p>
              Важно выбрать Купер в качестве партнёра, чтобы получать выплаты и автоматически учитывать доходы.
            </p>
            <ul>
              <li>Откройте «Мой налог»{nbsp}— сайт или приложение</li>
              <li>Перейдите в меню, раздел «Партнёры»</li>
              <li>Нажмите «Разрешить» в появившемся запросе от Купера</li>
              <li>Вернитесь на этот экран и нажмите «Подтвердить партнёрство»</li>
            </ul>
          </Col>
          <Col span={24}>
            <img src='/img/pencil.png' className='pencil' />
          </Col>
        </Row>
      </Col>
      <Col span={24} className='btn-block'>
        <Button
          className='btn-my-tax margin-top-20'
          size='large'
          block
          onClick={handleMyTax}
        >
          <img src='/img/fns.png' className='mr-3' />Перейти в «Мой налог»
        </Button>
        <Button
          ghost
          block
          size='large'
          type='primary'
          className='margin-top-20 margin-bottom-20'
          onClick={handleClick}
          loading={loading}
        >
          Подтвердить партнёрство
        </Button>
      </Col>
    </Row>
  )
}
